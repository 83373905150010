.button-container {
  text-align: center;
}
.button {
  max-width: 327px;
  width: 100%;
  height: 52px;
  border-radius: 12px;
  margin-top: 15px;
  cursor: pointer;
}
.cta-button {
  background: #846d3d;
  border: none;
  color: #fff;
}

.secondary-button {
  border: 1px solid #606060;
  background: none;
  color: #606060;
}
