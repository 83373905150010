/*
TEXT CLASSES
*/
.dark {
  color: #606060;
}
.gold {
  color: #846d3d;
}
.title {
  text-align: center;
  line-height: 1.5;
  font-size: 23px;
  margin-bottom: 23px;
}
.input-title {
  color: #606060;
  font-size: 11px;
  margin-left: 0.75rem;
}
.sub-title {
  font-size: 20px;
  text-decoration: underline;
  margin-bottom: 29px;
}
.info-text {
  font-size: 16px;
}
.white-container {
  background: #fff;
  max-width: 343px;
  width: 100%;
  margin: 50px auto;
  padding: 30px 8px 20px 8px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0px 4px 16px 0px rgba(69, 91, 99, 0.1);
}
.white-container .info {
  color: #606060;
}
/*
LOGO CLASSES
*/
.logo-container {
  text-align: center;
  margin-bottom: 23px;
}
.logo {
  height: 121px;
  width: 121px;
}
/*
FORM AND INPUT CLASSES
*/
form {
  max-width: 343px;
  margin-left: auto;
  margin-right: auto;
}
.form-input {
  box-shadow: 0px 4px 16px 0px rgba(69, 91, 99, 0.1);
  padding: 18px 24px;
  border-radius: 12px;
  height: 52px;
  background-color: #fff;
  border: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
input:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
input[type='email']::placeholder {
  background-image: url(../images/login-icn.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 30px 17px;
}
input[type='password']::placeholder {
  background-image: url(../images/pswd-icn.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 30px 17px;
}
.form-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #606060;
  font-size: 13px;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #606060;
  font-size: 13px;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #606060;
  font-size: 13px;
}
.password-input-container {
  margin-bottom: 25px;
  margin-top: 14px;
}
/*
ERROR CLASSES
 */
.input-error {
  color: #f70000;
  font-size: 12px;
  text-align: left;
  margin-left: 0.75rem;
  margin-top: 5px;
}
.input-error p {
  margin-bottom: 0px;
}
.error-container {
  color: #f70000;
  box-shadow: 0 0 0 1px #f70000;
  background: #ffe3ef;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 1rem;
}
.error-message {
  color: #f70000;
  font-size: 12px;
  margin-bottom: 0px;
  text-align: left;
}
.warning-notice {
  color: #f70000;
  margin-top: 23px;
}
